$_types: (
  primary: main-palette(500),
  secondary: main-palette(300),
  success: $success-color,
  info: $info-color,
  warning: $warning-color,
  danger: $error-color,
  light: main-palette(50),
  dark: main-palette(900)
);
.progress {
  background-color: rgba(#000, 0.1);
  border-radius: $shape;
  height: 12px;
  font-size: .7rem;
  overflow: hidden;

  @each $_type, $_color in $_types {
    &.#{$_type}-progress {
      .progress-bar {
        background-color: $_color;
      }
    }
  }

  &.xs {
    height: 4px;
  }
  &.sm {
    height: 8px;
  }
  &.lg {
    height: 14px;
  }
}
