@import "../variables";

.accordion {
  .card {
    margin-bottom: $module-rem;
  }
  .card-header {
    background-color: main-palette(600);
    padding: 0;
    position: relative;
    cursor: pointer;

    .title-icon {
      font-size: 15px;
      margin-bottom: auto;
      margin-top: 3px;
    }

    .title-text {
      padding-right: $module-rem * 3;
      text-align: left;
    }
    .title-icon +.title-text {
      padding-left: $module-rem;
    }

    &.lg {
      .btn {
        font-size: 1.1rem;
        padding-top: $module-rem * 2;
        padding-bottom: $module-rem * 2;
      }
    }
    &.sm {
      .btn {
        font-size: 0.9rem;
        padding-top: $module-rem;
        padding-bottom: $module-rem;
      }
    }
    .btn {
      color: accent-palette(500);
      display: flex;
      justify-content: start;
      text-decoration: none;
      padding: ($module-rem * 1.5);
      position: relative;
      width: 100%;
      white-space: normal;
      transition: color 0.2s ease-in-out;

      .plus-icon {
        display: block;
        height: 16px;
        width: 16px;
        margin-bottom: auto;
        right: 0;
        top: $module-rem * 1.6;
        position: absolute;
        &:before,
        &:after {
          background-color: currentColor;
          border-radius: 2px;
          content: "";
          position: absolute;
          right: $module-rem * 1.5;
          top: 50%;
          color: inherit;
          transform: translateY(-50%);
        }

        &:after {
          height: 16px;
          opacity: 0;
          right: calc(#{$module-rem * 1.5} + 7px);
          transition: opacity 0.2s ease-in-out;
          width: 2px;
        }

        &:before {
          height: 2px;
          width: 16px;
        }
      }
      &.collapsed {
        color: #fff;

        .plus-icon {
          &:after {
            opacity: 1;
          }
        }
      }

      &:hover {
        color: accent-palette(500);
        text-decoration: none;
      }
    }
  }
  &.alternate {
    .card-header {
      .btn {
        color: #fff;
        background-color: accent-palette(500);
        transition: background-color 0.2s ease-in-out;

        &.collapsed {
          background: transparent;
          color: #fff;
        }
      }
    }
  }
}
