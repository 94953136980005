html {
  background: $primary-bg;
  color: $default-color;
  font-family: $main-ff;
  font-size: 16px;
  max-height: 100vh;
  max-width: 100vw;
  height: 100%;
}
body {
  height: 100%;
  overflow-x: hidden;
  position: relative;
  max-height: 100vh;
  max-width: 100vw;
  width: 100%;

  &.boxed {
    .nav-content,
    .footer-wrap,
    .side-bar-wrap,
    .content-container {
      max-width: $boxed-width;
    }

    .side-bar-wrap {
      margin: auto;
    }
  }

  &.hide-search {
    .nav-bar .nav-bar-wrap .nav-content {
      .search {
        display: none;
      }
      .toggle-search-btn {
        display: none;
      }
      .page-title {
        opacity: 1 !important;
      }
    }
  }
}

$_nav-height: 5rem - $module-rem;
$_footer-height: 50px;
$_side-width: 28 * $module-rem;
$_content-padding: 3 * $module-rem;

.layout {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  .layout-wrap {
    height: 100%;
    width: 100%;

    .sidebar-overlay {
      bottom: 0;
      height: 100%;
      left: 0;
      position: fixed;
      width: 100%;

      @media #{$min992} {
        display: none;
      }
    }
    .main {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: column;
      min-height: 100%;
      min-width: 100%;
      padding: $nav-bar-height 0 0 0;

      .content-container {
        display: flex;
        flex: 1 0 auto;
        min-height: 100%;
        margin: 0 auto;
        padding: $content-v-indents $content-h-indents;
        position: relative;
        width: 100%;

        @media #{$max1199} {
          padding-left: $content-h-indents / 3 * 2;
          padding-right: $content-h-indents / 3 * 2;
        }

        .content {
          flex: 1 0 auto;
          min-width: 100%;
          max-width: 100%;
        }
        &.boxed {
          max-width: $boxed-width;
        }
      }
    }
  }

  &.vertical {
    .layout-wrap {
      .nav-bar {
        padding-left: $side-bar-width;
        @media #{$max991} {
          padding-left: 0;
        }
      }
      .main {
        padding-left: $side-bar-width;
        transition: padding 0.2s $animation;
        @media #{$max991} {
          padding-left: 0;
        }
      }
    }
  }
  &.horizontal {
    .layout-wrap {
      .nav-bar {
        .logo-col {
          @media #{$max991} {
            display: none;
          }

          .logo {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
      @media #{$min992} {
        .side-bar {
          background: main-palette(500);
          height: $horizontal-menu-height;
          top: $nav-bar-height;
          width: 100%;
          z-index: 998;

          .side-bar-wrap {
            background: none;
            padding: 0 $content-h-indents;
            @media #{$max1199} {
              padding-left: $content-h-indents / 3 * 2;
              padding-right: $content-h-indents / 3 * 2;
            }

            .logo {
              display: none;
            }
          }
          &.boxed {
            .side-bar-wrap {
              margin: 0 auto;
              max-width: $boxed-width;
            }
          }
          &.light {
            background: #fff;
          }
        }
        .main {
          padding-top: $nav-bar-height + $horizontal-menu-height;
        }
      }
    }
  }

  &.error {
    align-items: center;
    background: linear-gradient(90deg, #061e38 0, #516478);
    min-height: 100vh;
    overflow: hidden;
    flex-direction: column;
    padding: ($module-rem * 2) ($module-rem) ($module-rem * 6);
    text-align: center;
    width: 100%;

    .main {
      display: flex;
      flex-direction: column;

      .title {
        color: #fff;
        font-size: 12rem;
        @media #{$max767} {
          font-size: 6rem;
        }
      }
      .sub-title {
        font-size: 2rem;
        margin-top: 0;
        @media #{$max767} {
          font-size: 1.5rem;
        }
      }

      .error-message {
        color: rgba(#fff, 0.5);
        margin-top: 0;
      }

      .error-btn {
        max-width: 200px;
        margin: auto;
      }
    }
  }
  &.auth {
    display: block;
    height: 100%;
    width: 100%;

    .sign-up-page,
    .log-in-page {
      align-items: center;
      background: url("../../assets/content/login-bg.jpg") 50% 50% no-repeat;
      background-size: cover;
      display: flex;
      padding: ($module-rem * 2) $module-rem;
      justify-content: center;
      min-height: 100vh;
      overflow: hidden;
      width: 100%;

      > * {
        width: 100%;
        max-width: 400px;
      }
      .card {
        background-color: rgba(#fff, 0.2);

        .card-header {
          font-size: 1.3rem;
          text-align: center;
          font-weight: 500;
        }
        .card-body {
          padding: 30px 20px 0;
        }

        .btn {
          background-image: linear-gradient(to right, rgb(7, 34, 62) 0%, rgb(81, 100, 120) 100%);
          border: none;
        }
      }
      .login-form {
        padding: ($module-rem * 3) ($module-rem * 2) ($module-rem);
      }

      .forgot-box {
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;
        padding: $module-rem;
        margin-top: ($module-rem * 4);
        width: 100%;

        a {
          color: #fff;
          opacity: 0.8;
          font-size: 0.9em;
          text-decoration: none;
          transition: opacity 0.3s $animation;

          &:hover {
            opacity: 1;
          }
        }

        .divider {
          border-right: 1px solid rgba(#000, 0.2);
          display: inline-block;
          height: 2 * $module;
          margin: 0 ($module-rem * 1.5);
        }
      }
    }
    .sign-up-page {
      @extend .log-in-page;
      background: url("../../assets/content/signup-bg.jpg") 50% 50% no-repeat;

      .forgot-box {
        background: rgba(#000, .1);
        width: calc(100% + #{$module-rem * 4});
        margin-left: -$module-rem * 2;
        margin-right: -$module-rem * 2;
      }
    }
  }
  &.loaded {
    .overlay,
    .loader {
      display: none;
    }
  }
}
