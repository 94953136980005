@import "../variables";

$_types: (
  primary: (
    bg: main-palette(500),
    color: main-contrast(500)
  ),
  secondary: (
    bg: accent-palette(300),
    color: accent-contrast(300)
  ),
  success: (
    bg: $success-color,
    color: $success-contrast
  ),
  info: (
    bg: $info-color,
    color: $info-contrast
  ),
  warning: (
    bg: $warning-color,
    color: $warning-contrast
  ),
  danger: (
    bg: $error-color,
    color: $error-contrast
  ),
  light: (
    bg: main-palette(50),
    color: main-contrast(50)
  ),
  dark: (
    bg: main-palette(900),
    color: main-contrast(900)
  )
);

.notification-layout {
  position: fixed;
  z-index: 9999;
  border: none;
  pointer-events: none;
  top: $nav-bar-height + $module-rem * 2;
  right: $module-rem * 3;

  &.top-left {
    top: $nav-bar-height + $module-rem * 2;
    left: $module-rem * 3;
    right: unset;
  }

  &.bottom-left {
    top: unset;
    bottom: $footer-height + $module-rem * 3;
    left: $module-rem * 3;
  }

  .vertical & {
    &.top-left,
    &.bottom-left {
      left: $side-bar-width + $module-rem * 3;
    }
  }

  &.bottom-right {
    top: unset;
    bottom: $footer-height + $module-rem * 3;
    right: $module-rem * 3;
  }

  @media #{$max991} {
    right: $module-rem * 2;

    &.bottom-right {
      right: $module-rem * 2;
    }

    .vertical & {
      &.top-left,
      &.bottom-left {
        left: $module-rem * 2;
      }
    }

    &.top-left,
    &.bottom-left {
      left: $module-rem * 2;
    }
  }
}

.toast {
  border: none;
  pointer-events: all;
  display: none;
  border-radius: $shape;

  .toast-header {
    align-items: center;
    border: inherit;
    border-radius: $shape $shape 0 0;
    display: flex;

    .toast-icon {
      background: rgba(#fff, 0.2);
      padding: $module-rem / 2;
      border-radius: 50%;
      margin-right: $module-rem / 2;
      font-size: 20px;
    }
    .close {
      cursor: pointer;
      opacity: 0.6;
      font-size: 1.2em;
      font-weight: lighter;
      transition: opacity 0.3s $animation;

      > .icofont {
        font-weight: lighter;
      }
      &:hover {
        opacity: 1;
      }
      @extend .no-style;
    }
  }

  .toast-body {
    align-items: stretch;
    border: inherit;
    border-radius: 0 0 $shape $shape;
    display: flex;
  }

  @each $_type, $_color in $_types {
    &.#{$_type}-toast {
      .toast-header {
        background: rgba(map-get($_color, bg), 0.8);
        color: map-get($_color, color);

        .close {
          color: map-get($_color, color);
        }
      }
      .toast-body {
        background: map-get($_color, bg);
        color: map-get($_color, color);
      }
    }
  }
}
