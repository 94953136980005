$_animation: $animation;
$_panel-width: 26 * $module;
$_nav-height: 11 * $module-rem;

.settings-overlay {
  top: 0;
  left: 0;
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1010;

  &.open {
    display: block;
  }
}
.settings-panel {
  background: #2c435b;
  border-left: 1px solid main-palette(500);
  color: #fff;
  display: block;
  position: fixed;
  right: -$_panel-width;
  height: 100vh;
  top: 0;
  transition: right .3s $_animation;
  width: $_panel-width;
  z-index: 1011;

  &.open {
    right: 0;
  }
  .title-box {
    align-items: center;
    background: main-palette(500);
    display: flex;
    height: $nav-bar-height;
    justify-content: center;

    .title {
      font-size: 1.5em;
      margin: 0;
      color: #fff;
    }
  }

  .switcher-box {
    align-items: stretch;
    display: flex;
    flex-direction: column;

    .switcher-block {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      padding: 2 * $module-rem;

      .switcher {
        display: flex;
        flex-direction: row;

        .color-btn {
          border: 1px solid #e4e4e4;
          background: #fff;
          height: 22px;
          padding: $module-rem / 2;
          margin-left: $module-rem;
          width: 32px;

          &.dark {
            background-color: rgb(6, 30, 56)
          }
          &:first-child {
            margin: 0;
          }

          &.active {
            &:before {
              background: accent-palette(500);
              display: block;
              position: absolute;
              border-radius: 50%;
              content: '';
              left: -2px;
              height: 5px;
              top: -2px;
              width: 5px;
            }
          }
        }
      }
    }
  }
  .toggle-btn {
    font-size: 1.5em;
    left: -58px;
    padding: 7px;
    position: absolute;
    top: 300px;
    z-index: inherit;

    .icofont {
      font-size: 1.3em;
      margin: 0 ($module-rem / 2);
    }
  }
}
