.nav-actions {
  align-items: center;
  display: flex;
  margin-left: auto;
  position: relative;

  .item {
    margin: $module-rem / 2;

    .dropdown-menu {
      top: $module-rem * 1.4 !important;

      @media #{$max543} {
        left: $module-rem / 2 !important;
        right: $module-rem / 2 !important;
        position: fixed !important;
        margin: 0;
        transform: translate3d(0, 51px, 0) !important;
        z-index: 9998;
      }
    }
    .nav-link {
      color: #fff;
      font-size: 20px;
      padding: 0 $module-rem / 2;
      position: relative;
      transition: color 0.2s ease-in-out;

      &.active,
      &:hover {
        color: accent-palette(500);
      }
    }
  }
}
