.icon-list {
  margin: -.5rem;

  .icon {
    align-items: center;
    background: main-palette(400);
    color: #fff;
    display: inline-flex;
    font-size: 30px;
    justify-content: center;
    height: 45px;
    min-width: 45px;
    margin:  .5rem;
    padding: $module-rem;
    width: 45px;
  }
}
