$border: 1px solid rgba(#fff, 0.15);
$img-size: 12 * $module;

.contact {
  flex: 1;
  min-width: 100%;

  .img-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .contact-img {
      border-radius: 50%;
      display: flex;
      height: $img-size;
      margin-bottom: $module-rem;
      min-height: $img-size;
      min-width: $img-size;
      justify-content: center;
      overflow: hidden;
      width: $img-size;

      > img {
        height: 100%;
        position: relative;
      }
    }

    .contact-name {
      font-size: 1.3em;

      > a {
        color: main-contrast(500);
        font-weight: 500;
        text-decoration: none;
        transition: color 0.3s $animation;

        &:hover {
          color: accent-palette(500);
        }
      }
    }

    .contact-role {
      font-size: 1.1em;
      opacity: 0.6;
      margin-bottom: 2 * $module-rem;
    }

    .social-media {
      font-size: 1.4em;
      margin-bottom: 2 * $module-rem;

      :not(:first-child) {
        margin-left: $module-rem;
      }

      .icofont {
        cursor: pointer;
      }

      .icofont-social-facebook {
        color: #3b5998;
      }

      .icofont-social-google-plus {
        color: #db4437;
      }

      .icofont-social-twitter {
        color: #1da1f2;
      }
    }
  }

  .contact-item {
    border-top: $border;
    display: flex;
    flex-direction: row;
    font-size: 0.9em;
    justify-content: space-between;
    padding: $module-rem 0;
    
    a {
      text-decoration: none;
      color: #fff;
      opacity: .8;
      transition: opacity .2s ease-in-out;

      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }
    :first-child {
      opacity: 0.6;
    }
  }
}
