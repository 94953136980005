$_bg: (
  default: transparent,
  hover: transparent,
  active: main-palette(500),
  disabled: #ccc
);
$_border: (
  default: main-palette(500),
  hover: main-palette(500),
  active: main-palette(500),
  disabled: #ccc
);
$_color: (
  default: main-palette(500),
  hover: main-palette(500),
  active: accent-palette(500),
  disabled: #8e8e8e
);
$_label-color: (
  default: inherit,
  hover: inherit,
  active: inherit,
  disabled: #ccc
);

$_border-style: solid;
$_border-width: 2px;
$_fs: 1.2rem;
$_shape: 50%;
$_animation: $animation;
$_height: $module-rem * 2;
$_width: $module-rem * 2;

.custom-radio {
  color: #aaafb4;

  transition: color 0.2s $animation;
  .custom-control-label {
    color: map-get($_label-color, default);
    cursor: pointer;
    font-weight: 500;

    &:before,
    &:after {
      align-items: center;
      border-radius: $_shape;
      box-shadow: none !important;
      display: flex;
      height: $_height;
      justify-content: center;
      left: -($_width + $_module-rem);
      margin: 0;
      outline: none !important;
      padding: 0;
      top: 0;
      transition: background 0.2s $_animation, border 0.2s $_animation, transform 0.2s $_animation;
      width: $_width;
    }
    &:before {
      background: map-get($_bg, default);
      border: $_border-width $_border-style map-get($_border, default);
      content: "";
      pointer-events: initial;
    }
    &:after {
      background: map-get($_color, default);
      border-radius: $_shape;
      height: calc(#{$_height} - 10px);
      margin: 5px 0 0 5px;
      transform: scale(0);
      transition: background 0.2s $animation, transform 0.2s $animation;
      width: calc(#{$_height} - 10px);
    }
    &:hover {
      color: map-get($_label-color, hover);

      &:before {
        background: map-get($_bg, hover);
        border-color: map-get($_border, hover);
      }
      &:after {
        background: map-get($_color, hover);
        transform: scale(1);
      }
    }
  }
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &:before {
        background: map-get($_bg, active);
        border-color: map-get($_border, active);
      }
      &:after {
        background: map-get($_color, active);
        transform: scale(1);
      }
    }
    &:disabled ~ .custom-control-label {
      color: map-get($_color, disabled);
      cursor: not-allowed;

      &:before {
        background: map-get($_bg, disabled);
        border-color: map-get($_border, disabled);
      }
      &:after {
        background: map-get($_color, disabled);
        transform: scale(0);
      }
    }
    &:disabled:checked ~ .custom-control-label {
      &:before {
        background: map-get($_bg, disabled);
        border-color: map-get($_border, disabled);
      }
      &:after {
        transform: scale(1);
      }
    }
  }
}
