.accent-palette {
  color: accent-palette(500);
}

.text-danger {
  color: $error-color !important;
}

.nowrap {
  white-space: nowrap;
}

.hidden {
  display: none !important;
}

.no-style {
  background: none;
  border: 0;
  outline: none;
  padding: 0;
}

.elements-list {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: (-$module-rem) (-$module-rem / 2);

  > * {
    margin: ($module-rem) ($module-rem / 2);
  }
}

.chat-container {
  height: 200px !important;
  position: relative;
  overflow: hidden;

  &.container-h-100 {
    height: 100px !important;
  }

  &.container-h-150 {
    height: 100px !important;
  }

  &.container-h-50 {
    height: 50px !important;
  }

  &.container-h-300 {
    height: 300px !important;
  }

  &.container-h-400 {
    height: 400px !important;
  }

  &.negative-indents {
    margin-top: -$module-rem * 2;
    margin-bottom: -$module-rem * 2;
  }
}

.rotate {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.animated {
  &.delay-01s {
    animation-delay: 0.1s;
  }

  &.delay-02s {
    animation-delay: 0.2s;
  }

  &.delay-03s {
    animation-delay: 0.3s;
  }

  &.delay-04s {
    animation-delay: 0.4s;
  }

  &.delay-05s {
    animation-delay: 0.5s;
  }

  &.delay-06s {
    animation-delay: 0.6s;
  }

  &.delay-07s {
    animation-delay: 0.7s;
  }

  &.delay-08s {
    animation-delay: 0.8s;
  }

  &.delay-09s {
    animation-delay: 0.9s;
  }
}
*:focus {
  outline: none;
}
.rounded {
  &-20 {
    border-radius: 20px;
  }

  &-50 {
    border-radius: 50px;
  }

  &-500 {
    border-radius: 500px;
  }
}
.textarea {
  &-no-resize {
    resize: none;
  }
}
.list-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $module-rem * 2;

  img {
    border-radius: 50%;
    height: 50px;
    min-width: 50px;
  }
}

.info-card {
  background: transparent;
  &.gradient-1 {
    background-image: linear-gradient(to right, rgb(6, 30, 56) 0%, rgb(25, 48, 72) 100%);
  }

  &.gradient-2 {
    background-image: linear-gradient(to right, rgb(25, 48, 72) 0%, rgb(43, 65, 88) 100%);
  }

  &.gradient-3 {
    background-image: linear-gradient(to right, rgb(43, 65, 88) 0%, rgb(61, 81, 103) 100%);
  }

  &.gradient-4 {
    background-image: linear-gradient(to right, rgb(61, 81, 103) 0%, rgb(78, 98, 118) 100%);
  }

  .card-body {
    padding: $module-rem * 2.5;
    overflow: visible;
    .card-icon {
      font-size: 2.9rem;
    }
    .card-title {
      font-size: 1.5rem;
    }
  }
}

.rounded-icon {
  border-radius: 50%;
  font-size: 1.2rem;
  padding: 1rem;
}

.text-muted {
  color: #a1a7aa !important;
}

.table-row {
  align-items: center;
  display: flex;
  margin: 0 (-$module-rem / 2);

  .table-col {
    margin: 0 ($module-rem / 2);
  }
}

.media-card {
  .media-card-icon {
    color: #fff;
    cursor: pointer;
    font-size: 4em;
    margin-bottom: $module-rem;
  }
  .media-card_text {
    color: #fff;
    font-size: 1.3em;
    margin: 0;
  }
  .media-card-sub-text {
    color: #fff;
    opacity: 0.8;
  }
}

.article-card {
  align-items: stretch;
  display: flex;
  background-size: cover;
  background-position: 50% 50%;

  .card-body {
    background: rgba(#000, 0.7);
  }
  .article-card-title {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-top: 5 * $module-rem;

    .title-main {
      font-size: 1.8rem;
      margin-bottom: $module-rem / 2;
    }
  }
  .article-card-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;

    .action {
      display: flex;
      opacity: 0.8;
      justify-content: space-between;
      transition: opacity 0.3s $animation;

      > * {
        cursor: pointer;
        color: #fff;
        font-size: 1.2em;
        font-weight: lighter;
        padding-left: $module-rem/2;
      }
      .icon {
        &:not(:first-child) {
          margin-left: $module-rem/2;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
.negative-indents {
  margin: 0 (-$module-rem);
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
