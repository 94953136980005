$_overlay-bg: rgba(main-palette(500), 0.1);
$_bg: main-palette(400);
$_border-color: rgba(main-palette(500), 0.1);
$_shape: $shape;
$_transition: $animation;

.dropdown-menu  {
  background: $_bg;
  border-radius: $_shape;
  z-index: 9998;
  margin: ($module-rem / 4) 0;

  .inner {
    overflow-x: hidden;
  }
  &:not(.profile) {
    min-width: 280px;
  }

  .bootstrap-select & {
    min-width: unset !important;
  }

  .dropdown-item {
    color: accent-palette(500);
    padding: $module-rem ($module-rem * 2);
    transition: color .2s ease;

    &:hover,
    &:focus{
      background: initial;
      color: accent-palette(700);
    }
  }

  .dropdown-divider {
    border-top-color: rgba(#000, .15);
  }

  .avatar-block {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: $module-rem 0 0;
    flex-direction: column;

    img {
      border-radius: 50%;
      margin-bottom: $module-rem;
    }
  }
  .menu-list {
    font-size: 13px;
    list-style-type: none;
    margin: 0;
    padding: 10px 0;

    li {
      align-items: center;
      display: flex;
      padding: ($module-rem) ($module-rem * 2);
      .icon {
        align-items: center;
        background: #fff;
        display: flex;
        font-size: 20px;
        border-radius: 50%;
        margin-right: $module-rem;
        height: 36px;
        justify-content: center;
        width: 36px;
      }
      .profile-link {
        .icofont {
          margin-right: $module-rem;
          font-size: 1.1em;
        }

        font-size: 1.2em;
      }
      a {
        color: #fff;
        display: block;
        //padding: 5px 15px;
        text-decoration: none;

        &:hover {
          color: #fff;
        }
      }
    }
  }
  .menu-header,
  .menu-footer {
    margin: 0;
    padding: $module-rem ($module-rem * 2);
    text-align: center;
  }
  .menu-header {
    border-bottom: 1px solid $_border-color;
    border-radius: $_shape $_shape 0 0;
    color: main-palette(500);

    .menu-title {
      color: #fff;
      font-size: 1.2rem;
      line-height: 1.3;
      margin: 0;
    }
  }
  .menu-footer {
    border-top: 1px solid $_border-color;
    border-radius: 0 0 $_shape $_shape;

    a {
      font-size: 0.8rem;
    }
  }
  .list {
    list-style-type: none;
    margin: 0;
    max-height: 252px;
    overflow: auto;
    padding: 0;

    > li {
      padding: $module-rem ($module-rem * 2);
      > a {
        border-top: 1px solid $_border-color;
        color: #fff;
        display: flex;
        text-decoration: none;
        transition: background 0.2s $_transition, color 0.2s $_transition;

        .avatar,
        .icon {
          background: rgba(main-palette(500), 0.1);
          border-radius: 50%;
          font-size: 22px;
          height: auto;
          padding: 11px;
          transition: background 0.2s $_transition;
          width: auto;
        }
        .avatar {
          border-radius: 50%;
          height: 40px;
          width: 40px;
        }
        .content {
          font-size: 0.85rem;

          .desc {
            display: block;
          }
          .date,
          .size {
            display: block;
            font-size: 0.75rem;
            font-style: italic;
            margin-top: 4px;
            opacity: 0.6;
          }
          .meta {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            line-height: 1.3;
            margin-bottom: 4px;

            .date {
              margin: 0 0 0 11px;
            }
          }
        }
        &:hover {
          background: rgba(main-palette(500), 0.05);

          .icon {
            background: rgba(main-palette(500), 0.2);
          }
        }
      }
    }
  }
  .author-info {
    padding: ($module-rem * 2) ($module-rem) 0;
    text-align: center;

    .author-name {
      font-size: 1rem;
    }
  }
}
.dropdown-overlay {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.1s opacity 0.2s $_transition;
  z-index: 9997;

  .overlay-bg {
    background: $_overlay-bg;
    display: block;
    height: 100%;
    width: 100%;
  }
}
