$_module-rem: $module-rem;
$_shape: $shape;
$_title-fs: 1.3571429rem;
$_title-fw: 500;
$_bg-color: rgba(main-palette(500), 0.4);
$_border-color: main-palette(500);
$_color: $default-color;
$_border-width: 0;
$_header-bg-color: rgba(main-palette(500), 0.5);
$_header-color: #fff;

.card {
  background: $_bg-color 50% 50% no-repeat;
  background-size: cover;
  border: 0;
  border-radius: $_shape;
  display: block;
  margin-bottom: $_module-rem * 2;
  position: relative;

  &.with-img {
    overflow: hidden;
  }
  .card-img {
    border-radius: $_shape;
    width: 100%;
  }
  .card-img + .card-body {
    background: rgba(#000, 0.7);
  }
  .card-header {
    background: $_header-bg-color;
    border: 0;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    font-size: $_title-fs;
    font-weight: $_title-fw;
    margin: 0;
    padding: $module-rem ($module-rem * 2);
  }
  .card-body {
    display: block;
    padding: $module-rem * 2.5;
    position: relative;
    width: 100%;
  }
  .card-img-top {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .card-img-bottom {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .bg-image {
    border-radius: inherit;
    height: auto;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: auto;
    min-width: 100%;
    transform: translateX(-50%) translateY(-45%);
    width: auto;
  }
  img {
    //max-width: 100%;
    height: auto;
  }
  &.with-bg-image {
    .card-body {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  &.border-primary,
  &.border-secondary,
  &.border-success,
  &.border-danger,
  &.border-warning,
  &.border-danger,
  &.border-info,
  &.border-light,
  &.border-dark {
    background: none;
    border-style: solid;
    border-width: 1px;

    .card-header {
      background: rgba(main-palette(500), 0.1);
    }
  }
}
