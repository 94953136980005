$_header-bg: main-palette(500);
$_row-hover-bg: main-palette(500);

$table-border: solid 1px main-palette(500);
$scroll-height: 40 * $module;
$input-color: rgba(#000, 0.9);

$_shape: $shape;
$_animation: $animation;

$_table-colors: (
  "primary": main-palette(500),
  "secondary": accent-palette(500),
  "success": $success-color,
  "info": $info-color,
  "warning": $warning-color,
  "danger": $error-color
);
.table {
  margin-bottom: $module-rem * 2;
  border: 1px solid main-palette(500);
  border-radius: $_shape;
  overflow: auto;
  width: 100%;

  >:not(:last-child)>:last-child>* {
    border-bottom-color: inherit;
  }
  &.dataTable {
    thead {
      th {
        padding: $module-rem;
        background-position: 90% 50% !important;
      }
    }
    tbody {
      tr {
        background: #2c435b;

        td {
          min-width: 45px;
        }
      }
    }
  }
  &.table-hover {
    tr {
      &:hover {
        background: main-palette(500);
      }
    }
  }
  &.table-borderless {
    border: none;
  }
  &.table-sm {
    tr {
      td {
        padding: $module-rem / 2;
      }
    }
  }
  &.table-bordered {
    tr {
      &:last-child {
        td {
          border-bottom: 1px solid rgba(#fff, 0.35);
        }
      }
      th {
        border-color: main-palette(500);
      }
      td {
        border-color: rgba(#fff, 0.35);
      }
    }
  }

  tr {
    background: #2c435b;
    transition: background 0.2s $_animation, border 0.2s $_animation, color 0.2s $_animation;
    width: 100%;

    @each $_variant, $_color in $_table-colors {
      &.table-#{$_variant} > td{
        background-color: $_color;
      }
    }
    &:last-child {
      td {
        border-bottom: none;
      }
    }
    th {
      color: #fff;
      border-top: none;
    }
    th,
    td {
      border-bottom: 1px solid rgba(#000, 0.15);
      border-top: none;
      color: #fff;
      padding: $module-rem;
      vertical-align: middle;
      transition: background 0.2s $_animation, border 0.2s $_animation, color 0.2s $_animation;

      &:first-child {
        padding-left: $module-rem * 2;
      }
      &:last-child {
        padding-right: $module-rem * 2;
      }
    }
    th {
      align-items: center;
      background: $_header-bg;
      color: #fff !important;
      opacity: 1;
      position: relative;

      .search-box {
        width: 100%;

        tc-input {
          min-width: 6 * $module-rem;
        }
      }
      .icons {
        display: inline-block;
        float: right;
        opacity: 0.8;

        .arrows {
          display: flex;
          font-size: 0.8em;
          flex-direction: column;
          height: 100%;
          padding: 0 0 0 $module-rem;

          .up {
            transition: color 0.3s $animation;

            &.active {
              color: accent-palette(400);
              opacity: 1;
            }
          }
          .down {
            transition: color 0.3s $animation;

            &.active {
              color: accent-palette(400);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.dataTables_wrapper {
  .dataTables_length {
    label {
      color: #fff;

      .bootstrap-select {
        width: 220px !important;
      }
      select {
        background: main-palette(500);
        color: #fff;
        border: none;
        margin: 0 5px;
      }
    }
  }
  .dataTables_paginate,
  .dataTables_info {
    color: #fff;
    padding-top: $module-rem;
  }
  .dataTables_paginate {
     .paginate_button {
       margin-left: $module-rem / 2;
     }

    @media #{$max767} {
      display: flex;
      justify-content: center;

      span {
        max-width: 100%;
        overflow-x: auto;
      }
    }
  }
  .dataTables_paginate.paging_simple_numbers {
    .paginate_button {
      &:not(:first-child) {
        margin-left: 5px;
      }

      background: main-palette(400);
      border-radius: $_shape;
      border: none;
      color: #fff !important;
      cursor: pointer;
      float: left;
      font-size: 0.8em;
      padding: 4px 8px;
      text-decoration: none;
      transition: background-color 0.2s $animation, color 0.2s $animation;

      &:hover {
        color: #fff !important;
        border: none;
        background: main-palette(800);
      }
      &.current {
        background-color: main-palette(500);
        color: main-contrast(700);
      }
      &.disabled {
        background: main-palette(200);
        color: main-contrast(200);
        pointer-events: none;
      }
    }
  }
}
.table-responsive {
  width: 100%;
}
