$padding_h: 0;
$padding_v: $module-rem;

.todo-list {
  .todo-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .todo-item {
      align-items: baseline;
      border-bottom: 1px solid rgba(#fff,.25);
      display: flex;
      padding: $padding_v $padding_h;
      justify-content: space-between;

      &:first-child {
        border-top: 1px solid rgba(#fff,.25);
      }

      &.done {
        label {
          text-decoration: line-through;
        }
      }
      .delete-btn {
        cursor: pointer;
        color: $error-color;
        font-size: 1.4em;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
