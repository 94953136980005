@import "./variables";

label {
  display: inline-block;
}

.form-check-label {
  margin: 0;
}


.custom-control-label:after, .custom-control-label:before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;

  .custom-control-label:after,
  .custom-control-label:before {
    position: absolute;
  }

  .custom-radio .custom-control-label:after,
  .custom-checkbox .custom-control-label:before {
    align-items: center;
    border-radius: 0;
    box-shadow: none !important;
    justify-content: center;

    display: flex;
    height: 1.25rem;
    width: 1.25rem;
    left: -1.875rem;

    margin: 0;
    outline: 0 !important;
    padding: 0;
    top: 0;

    transition: background 0.2s ease-in-out, border 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
}

.custom-control {
  padding-left: 1.875rem;
  transition: color 0.2s ease-in-out;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-radio {
  height: 24px;
}

.custom-control-label {
  position: relative;
  height: 24px;
  margin: 0;
}

.custom-radio .custom-control-label {
  margin: 0;
  min-height: 24px;

  &:after {
    background: #07223e;
    border-radius: 50%;
    height: calc(1.25rem - 10px);
    margin: 5px 0 0 5px;
    position: absolute;
    content: "";

    transform: scale(0);
    transition: background 0.2s ease-in-out, transform 0.2s ease-in-out;

    width: calc(1.25rem - 10px);
  }
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background: #ff8534;
  transform: scale(1);
}
