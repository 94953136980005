@import "../variables";

$_bg: (
  default: transparent,
  hover: transparent,
  focus: transparent,
  disabled: rgba(#ccc, 0.1)
);
$_border: (
  default: rgba(#fff, 0.5),
  hover: rgba(#fff, 1),
  focus: accent-palette(500),
  disabled: #ccc
);
$_color: (
  default: #fff,
  hover: #fff,
  focus: #fff,
  disabled: #ccc
);

$_placeholder-color: #a8a8a8;
$_border-style: solid;
$_border-width: 1px;
$_fs: 1rem;
$_lh: $module-rem * 2 / $_fs;
$_padding-h: $module-rem * 2;
$_padding-v: calc(#{$module-rem} - #{$_border-width});
$_shape: $shape;
$_animation: $animation;

$_module-rem: $module-rem;
$_shadow-hover: none;

// size sm
$_fs-sm: ($_fs * 0.9);
$_lh-sm: $_module-rem * 2 / $_fs-sm;
$_shape-sm: ($_shape * 0.8);
$_padding-v-sm: ($_module-rem / 2);

// size lg
$_fs-lg: ($_fs * 1.3);
$_lh-lg: $_module-rem * 2 / $_fs-lg;
$_shape-lg: ($_shape * 1.5);
$_padding-v-lg: $_module-rem * 1.5;

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

.form-control {
  background: map-get($_bg, default);
  border: $_border-width $_border-style map-get($_border, default);
  border-radius: $_shape;
  color: map-get($_color, default);
  display: block;
  font-size: $_fs;
  height: auto;
  line-height: $_lh;
  outline: none;
  padding: $_padding-v $_padding-h;
  transition: background 0.2s $_animation, border 0.2s $_animation, box-shadow 0.2s $_animation, color 0.2s $_animation;
  will-change: background, border, box-shadow, color;
  width: 100%;

  @include placeholder {
    color: $_placeholder-color;
  }
  &:hover {
    background: map-get($_bg, hover);
    border-color: map-get($_border, hover);
    box-shadow: $_shadow-hover;
    color: map-get($_color, hover);
  }
  &:focus {
    background: map-get($_bg, focus);
    border-color: map-get($_border, focus);
    box-shadow: $_shadow-hover;
    color: map-get($_color, focus);
  }

  .input-group-text {
    background-color: rgba($error-color, 0.1);
    border: 1px solid $error-color;
  }

  .has-error & {
    .tc-input-wrap {
      background-color: rgba($error-color, 0.1);
      border: 1px solid $error-color;
    }
  }
  &.form-control-sm {
    border-radius: $_shape-sm;
    font-size: $_fs-sm;
    height: $_module-rem * 3;
    line-height: $_lh-sm;
    padding-top: $_padding-v-sm;
    padding-bottom: $_padding-v-sm;

    .input-icon {
      font-size: 1.1em;
    }
  }
  &.form-control-lg {
    border-radius: $_shape-lg;
    font-size: $_fs-lg;
    height: $_module-rem * 5;
    line-height: $_lh-lg;
    padding-top: $_padding-v-lg;
    padding-bottom: $_padding-v-lg;
  }
  &:disabled,
  &[readonly] {
    background-color: map-get($_bg, disabled);
    border-color: map-get($_border, disabled);
    box-shadow: none !important;
    color: map-get($_color, disabled);
    cursor: not-allowed;
  }
}
.form-control-plaintext {
  color: map-get($_color, default);
  font-size: $_fs;
  height: $_module-rem * 4;
  line-height: $_lh;
  padding-top: $_padding-v;
  padding-bottom: $_padding-v;

  &.form-control-sm {
    font-size: $_fs-sm;
    height: $_module-rem * 3;
    line-height: $_lh-sm;
    padding-top: $_padding-v-sm;
    padding-bottom: $_padding-v-sm;
  }
  &.form-control-lg {
    font-size: $_fs-lg;
    height: $_module-rem * 5;
    line-height: $_lh-lg;
    padding-top: $_padding-v-lg;
    padding-bottom: $_padding-v-lg;
  }
}
.form-group {
  margin: 0 0 ($module-rem * 2);
  position: relative;

  .valid-tooltip,
  .invalid-tooltip {
    color: #fff;
    font-size: 10px;
    padding: 0.05rem ($module-rem / 2);
    border-radius: $shape;
  }
  .form-label {
    display: block;
    font-weight: inherit;
    margin: 0 0 0.625rem;
  }
  .form-description {
    color: rgba(255, 255, 255, 0.6);
    display: block;
    font-size: 0.8rem;
    margin: 0.625rem 0 0;
  }
}
.form-text {
  display: block;
  margin: $module-rem 0 0;
}
label {
  font-weight: 600;
  margin: 0 0 $module-rem;
}
.input-group-text {
  background: map-get($_bg, default);
  border: $_border-width $_border-style map-get($_border, default);
  border-radius: $_shape;
  color: $error-color;
  padding-left: $_padding-h;
  padding-right: $_padding-h;
  height: 40px;
}

.input-group {
  &-prepend {
    border-right: none;
  }
  &-append {
    border-left: none;
  }
  .btn {
    border-radius: $_shape;
  }
}
.with-prefix-icon,
.with-suffix-icon {
  position: relative;

  .prefix-icon,
  .suffix-icon {
    align-items: center;
    color: $_placeholder-color;
    display: flex;
    height: 100%;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    width: $_module-rem * 4;
    top: 0;
  }
  .prefix-icon {
    left: 0;
  }
  .suffix-icon {
    right: 0;
  }
}
.with-prefix-icon {
  .form-control {
    padding-left: ($_module-rem * 4);
  }
}
.with-suffix-icon {
  .form-control {
    padding-right: ($_module-rem * 4);
  }
}
.typeahead__field {
  .typeahead__cancel-button {
    color: #fff;
    font-size: 1.2em;
    transition: color 0.2s ease-in-out;
    z-index: 5;
  }

  .prefix-icon ~ .form-control {
    padding-left: $module-rem * 4;
  }

  .form-control:not(:placeholder-shown) {
    ~ .suffix-icon {
      opacity: 0;
    }
    ~ .typeahead__cancel-button {
      opacity: 1;
    }
  }

  .input-group-append,
  .input-group-prepend {
    border: $_border-width $_border-style map-get($_border, default);

    .input-group-text {
      border: none;
    }
  }

  .input-group-append {
    border-left-width: 0;
  }

  .input-group-prepend {
    border-right-width: 0;
  }

  .autocomplete-control {
    background-color: transparent;
    border: $_border-width $_border-style map-get($_border, default);
    padding-bottom: $_padding-v !important;
    padding-left: $_padding-h;
    padding-top: $_padding-v !important;

    &:read-only {
      background: map-get($_bg, default) !important;
      border-color: map-get($_border, default) !important;
      color: map-get($_color, default) !important;
    }
    &:hover {
      background: map-get($_bg, hover);
      border-color: map-get($_border, hover);
      box-shadow: $_shadow-hover;
      color: map-get($_color, hover);
    }
    &:active,
    &:focus {
      background: map-get($_bg, focus);
      border-color: map-get($_border, focus);
      box-shadow: $_shadow-hover;
      color: map-get($_color, focus);
    }
  }
}

.typeahead__list {
  background: main-palette(400);
  border-radius: 0 0 $_shape $_shape;
  border: none;
  box-shadow: 0px 20px 40px rgba(#000, 0.5);
  list-style-type: none;
  overflow: auto;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 9999;

  .typeahead__item {
    border-bottom: 1px solid rgba(#000, 0.05);
    border-top: none;
    cursor: pointer;
    padding: 0;
    transition: background 0.2s $_animation;

    a {
      padding: ($module-rem / 2) (calc(#{$module-rem * 2} + 2px));
    }

    .typeahead__display strong {
      color: accent-palette(500);
      font-weight: normal;
    }
    &.active {
      background: main-palette(500);
    }

    a {
      color: #fff;
    }

    &:not([disabled]) {
      > a:hover {
        color: #fff;
        background-color: rgba(#000, 0.05);
      }
      &:hover,
      &.active > a {
        color: #fff;
        background: rgba(#000, 0.05);
      }
    }

    &:last-child {
      border: none;
    }
  }
}

.dataTables_filter {
  label {
    align-items: center;
    color: #fff;
    display: flex;
  }
  input {
    @extend .form-control;
  }
}
.was-validated .form-control:invalid,
.was-validated .form-control:valid {
  background-position: calc(100% - #{$_padding-h / 2}) calc(0.375em + 0.1875rem);
}

.was-validated {
  &.form-control:invalid,
  &.form-control:valid {
  }
}
