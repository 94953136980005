@import "../variables";
$_shape: $shape;
$_animation: $animation;

$_border-color: rgba(#fff, 0.302);
$_hovered-border: rgba(#fff, 0.4);
$_focus-border: accent-palette(500);

$_bg: #2c435b;
$_list-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
$_list-bg: main-palette(400);
$_focus-bg: $_bg;

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}
.bootstrap-select {
  .dropdown-toggle {
    background: transparent;
    color: #fff;
    width: 100%;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &:hover {
      border-color: accent-palette(500);
    }
    &:focus {
      outline: none !important;
      box-shadow: none;
    }
    &.btn-light:not(:disabled):not(.disabled):active {
      background: transparent !important;
      color: #fff !important;
      border-color: accent-palette(500) !important;
    }
  }
  &.show {
    .dropdown-toggle {
      background: transparent;
      border-color: accent-palette(500);
      color: #fff;
      &.btn {
        &:focus {
          box-shadow: none !important;
          outline: none;
        }
      }

      .btn-light:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active,
      &:active {
        background: transparent !important;
        color: #fff !important;
        border-color: accent-palette(500) !important;
      }
    }
  }
  .dropdown-menu {
    margin: 0;
    width: 100%;
    background: main-palette(500);
    padding: ($module-rem * 2) 0;
    box-shadow: $_list-shadow;

    .dropdown-item {
      color: #fff;
      padding: ($module-rem / 2) ($module-rem * 2);

      &:hover {
        color: accent-palette(600);
      }
      &.active {
        background-color: transparent;
        color: accent-palette(500);
      }
    }
  }
}
