$_light-sidebar-bg: #fff;
$_light-logo-bg: #fff;

$_dark-sidebar-bg: main-palette(500);
$_dark-logo-bg: main-palette(500);

.side-bar {
  background: main-palette(500) url("../images/v-navbar-bg.jpg") 50% 50% no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s $animation;
  max-height: 100vh;
  width: $side-bar-width;
  z-index: 999;
  @media #{$max991} {
    transform: translateX(-$side-bar-width);
  }

  .side-bar-wrap {
    background: rgba(main-palette(500), 0.8);
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .close-btn-block {
      align-items: center;
      display: flex;
      position: absolute;
      right: $module-rem * 2;
      height: 60px;
      font-size: 45px;
      cursor: pointer;
      @media only screen and (min-width: 768px) {
        display: none;
      }

      a {
        text-decoration: none;
      }
    }
    .menu-wrap {
      @media #{$max991} {
        overflow-y: auto;
      }

      &.vertical {
        overflow-y: auto;
      }
    }
    .mCustomScrollBox {
      width: 100%;

      .mCSB_container {
        margin: 0;
      }
    }
  }
  &.opened {
    transform: translateX(0);

    .side-bar-overlay {
      display: block;
    }
  }
  &.light {
    .side-bar-wrap {
      background: rgba(#fff, 0.8);
      color: #000;
    }
  }
  .side-bar-overlay {
    position: fixed;
    top: $nav-bar-height;
    left: $side-bar-width;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: none;
    z-index: 999;
  }
}
