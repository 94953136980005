html {
  font-size: $main-fs;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #516478;
  color: $default-color;
  font-family: $main-ff;
  font-size: 1rem;
  font-weight: $main-fw;
  line-height: $main-lh;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $second-ff;
  font-weight: 500;
  margin-top: $module-rem;
  margin-bottom: $module-rem * 2;
}

h1, .h1 {
  font-size: 4.2142857rem;
  line-height: 1.13;
}

h2, .h2 {
  font-size: 3.1428571rem;
  line-height: 1;
}

h3, .h3 {
  font-size: 2.3571429rem;
  line-height: 1.34;
}

h4, .h4 {
  font-size: 1.7857143rem;
  line-height: 1.32;
}

h5, .h5 {
  font-size: 1.3571429rem;
  line-height: 1.21;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.6;
}

p {
  font-size: 1rem;
}

p, ul, ol, pre, blockquote {
  margin-top: 0;
  margin-bottom: $module-rem * 2;
}

blockquote {
  border-left: 3px solid accent-palette(500);
  font-size: 1.29rem;
  font-weight: 400;
  line-height: 1.33;
  margin-left: 3 * $module-rem;
  padding-left: 2 * $module-rem;
}

a {
  color: accent-palette(500);
  text-decoration: none;
  transition: color .2s $animation;

  &:hover {
    color: accent-palette(700);
  }
}

a,
a:visited,
a:focus,
a:active,
a:hover {
  outline: 0 none !important;
}

button:focus {
  outline: none !important;
}
