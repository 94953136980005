$_bg: #2c435b;
$_color: #fff;
$_accent-color: main-palette(500);
$_pricing-bg: #2c435b;

$_shape: $shape;

$_pricing-colors: (
        "primary": main-palette(500),
        "secondary": accent-palette(500),
        "success": $success-color,
        "info": $info-color,
        "warning": $warning-color,
        "danger": $error-color
);

.pricing {
  background: $_bg;
  border-color: $_accent-color;
  border-style: solid;
  border-width: 1px;
  border-radius: $_shape;
  color: $_color;
  display: flex;
  overflow: hidden;
  margin-bottom: 2 * $module-rem;
  flex-direction: column;

  @each $_variant, $_color in $_pricing-colors {
    &.pricing-#{$_variant} {
      border-color: $_color;

      .pricing-header {
        background-color: $_color;
      }
    }
  }
  .pricing-header {
    align-items: center;
    background: $_accent-color;
    text-align: center;
    padding: $module-rem;

    .title {
      color: #fff;
      padding: 0;
      margin: 0;
    }
  }
  .price-box {
    align-items: center;
    background: $_pricing-bg;
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    justify-content: center;
    padding: ($module-rem * 2) 0;

    p {
      font-size: 0.7em;
      opacity: 0.6;
    }
  }

  .pricing-body {
    background: $_pricing-bg;
    color: $_color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 ($module-rem * 2) ($module-rem * 2);

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding: $module-rem 0;
        text-align: center;
        opacity: 0.6;

        &:not(:first-child) {
          border-top: 1px solid rgba(#000, 0.15);
        }
      }
    }
  }
}
