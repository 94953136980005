@import "../variables";
@import "../helpers";

$_types: (
  primary: (
    bg: main-palette(500),
    color: main-contrast(500),
  ),
  secondary: (
    bg: accent-palette(500),
    color: accent-contrast(500),
  ),
  success: (
    bg: $success-color,
    color: $success-contrast,
  ),
  info: (
    bg: $info-color,
    color: $info-contrast,
  ),
  warning: (
    bg: $warning-color,
    color: $warning-contrast,
  ),
  danger: (
    bg: $error-color,
    color: $error-contrast,
  ),
);

.alert {
  border-radius: $shape;
  border: none;
  width: 100%;
  padding: $module-rem ($module-rem * 2);
  font-size: 1.1rem;

  .btn-close {
    box-shadow: none;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }

    @extend .no-style;
  }

  &.with-icon {
    position: relative;
    overflow: hidden;
    padding-left: $module-rem * 7;

    &.right {
      padding-right: $module-rem * 7;
      padding-left: $module-rem * 2;

      .icon {
        right: 0;
        left: unset;
      }
    }

    .icon {
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      height: 100%;
      background: rgba(#000, 0.15);
      left: 0;
      font-size: 20px;
      top: 0;
      width: $module-rem * 5;
    }
  }

  @each $_type, $_color in $_types {
    &.alert-#{$_type} {
      background: map-get($_color, bg);
      border-color: map-get($_color, bg);
      color: map-get($_color, color);

      &.alert-outline {
        border: 1px solid map-get($_color, bg);
        color: map-get($_color, bg);
        background: transparent;
      }
    }
  }

  &-primary {
    background-color: accent-palette(500);
    color: accent-contrast(500);
  }

  &-danger {
    background-color: $error-color;
    color: $error-contrast;
  }

  &-success {
    background-color: $success-color;
    color: $success-contrast;
  }

  &-warning {
    background-color: $warning-color;
    color: $warning-contrast;
  }

  &-info {
    background-color: $info-color;
    color: $info-contrast;
  }
}
