.marker {
  background: accent-palette(400);
  border-radius: 50%;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: -$module-rem / 2;
  top: 0;
  width: 8px;

  &.blue {
    background: $info-color;
  }
}
