.modal-dialog {
  .modal-content {
    background: main-palette(400);

    .modal-header {
      align-items: baseline;
      border-bottom-color: rgba(#000, 0.2);
      display: flex;
      justify-content: space-between;

      .close {
        color: #fff;
        padding: 0;
        margin: 0;
        text-shadow: none;
        opacity: 1;
        transition: color .2s ease-in-out;

        &:hover {
          color: $error-color;
        }
        @extend .no-style;
      }
    }
    .modal-footer {
      border-top-color: rgba(#000, .2);
    }
  }
  &.modal-dialog-bottom {
    display: flex;
    align-items: flex-end;
    min-height: calc(100% - #{$footer-height} - #{$module-rem * 4});
  }
}
