@keyframes loaderAnimation {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.app-footer {
  background: linear-gradient(90deg, #061e38 0, #516478);
  display: block;
  margin: 0 auto;
  bottom: 0;
  width: 100%;

  .footer-wrap {
    padding: (1.5 * $module-rem) (3 * $module-rem);
    margin: 0 auto;
    width: 100%;
    @media #{$max1199} {
      padding-left: $module-rem * 2;
      padding-right: $module-rem * 2;
    }
  }

  p {
    color: #fff;
    margin: 0;
    font-size: .8em;
  }

  &.boxed {
    .footer-wrap {
      max-width: $boxed-width;
    }
  }
}
