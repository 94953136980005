@import "../variables";

$_border: 1px solid rgba(#000, 0.12);

$_tab-bg-active: linear-gradient(to right, #0a48fd, #fe6666);
$_tab-bg-hover: rgba(#9ca09c, 0.2);
$_tab-active-color: rgba(#fff, 0.9);

$_tabs-br: 5px 5px 0 0;

$_tab-color: rgba(#000, 0.8);
$_tab-disabled-color: rgba(#fff, 0.5);

$_link-padding: $module/2;

$_content-bg: #dcdcdc;
$_content-color: #000;

$_shape: $shape;

.tabs {
  background: rgba(main-palette(500), 0.4);
  .nav-tabs {
    align-items: center;
    background: rgba(main-palette(500), 0.5);
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: $module-rem 0 0 ($module-rem * 3);
    overflow-y: hidden;
    overflow-x: auto;

    .nav-link {
      background: transparent;
      border: none;
      border-bottom: 2px solid transparent;
      color: accent-contrast(500);
      margin-right: 2 * $module-rem;
      cursor: pointer;
      padding: 0 0 $module-rem 0;
      opacity: 0.8;
      transition: border-color 0.3s $animation, color 0.3s $animation, opacity 0.3s $animation;
      white-space: nowrap;

      &.active {
        background: transparent;
        border-bottom: 2px solid accent-palette(500);
        color: accent-contrast(500);
        opacity: 1;
      }
      &:hover {
        border-bottom: 2px solid accent-palette(500);
        opacity: 1;
      }
      &.disabled {
        color: $_tab-disabled-color;
        pointer-events: none;
      }
    }
  }
  &.alternative {
    background: transparent;
    .nav-tabs {
      padding-bottom: 2 * $module-rem;
      background: transparent;
      border: none;
      vertical-align: middle;

      .nav-link {
        border: 1px solid transparent;
        border-radius: 50px;
        margin: 0 $module-rem/2 0 0;
        padding: $module-rem/2 2 * $module-rem;
        transition: background 0.3s $animation, border-color 0.3s $animation, color 0.3s $animation;

        &.active {
          border: 1px solid main-palette(500);
          background: main-palette(500);
          color: #fff;
          padding: $module-rem/2 2 * $module-rem;
          transition: background 0.3s $animation;

          &:hover {
            color: #fff;
          }
        }
        &:hover {
          border: 1px solid main-palette(500);
        }
      }
    }
    .tab-content {
      background: rgba(main-palette(500), .4);
    }
  }

  .tab-content {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: $shape;
    display: block;
    margin: 0;
    padding: ($module-rem * 2) ($module-rem * 3);
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }
}
