$_width: 4 * $module-rem;
$_height: 2 * $module-rem;

$_padding-left: $module-rem * 3;

$_border-color: main-palette(500);

.custom-switch {
  position: relative;
  height: 24px;

  .custom-control-input:not(:disabled):checked ~ .custom-control-label {
    &:after {
      background-color: accent-palette(500);
      transform: translateX(($_width - $_height / 1.6) - 0.4rem);
    }
    &:before {
      background-color: #07223e;
    }
  }

  .custom-control-input:disabled ~ .custom-control-label {
    &:before {
      background: rgba(#fff, 0.1);
      border-color: rgba(#fff, 0.1);
    }

    &:after {
      background-color: rgba(#fff, 0.5);
    }
  }

  .custom-control-input:disabled:checked ~ .custom-control-label {
    &:before {
      background: rgba(main-palette(500), 0.5);
      border-color: rgba(#fff, 0.1);
    }

    &:after {
      background-color: rgba(#fff, 0.2);
      transform: translateX(($_width - $_height / 1.6) - 0.4rem);
    }
  }

  .custom-control-label {
    margin: 0;
    cursor: pointer;
    position: relative;
    height: 24px;
    padding-left: $_width / 2;

    &:before {
      background-color: transparent;
      border-radius: $_height / 2;
      border: 1px solid main-palette(500);
      height: $_height;
      width: $_width;
      left: -$_padding-left;
    }

    &:after {
      background-color: #fff;
      border-radius: 50%;
      height: calc(#{$_height / 1.6});
      left: calc(#{-$_padding-left} + 1px);
      top: 0.47rem !important;
      transform: translateX(($_height - ($_height / 1.6)) / 2);
      transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
      width: calc(#{$_height / 1.6});
    }
  }
}

.custom-control {
  &.custom-switch {
    &:checked {
    }
  }
}
