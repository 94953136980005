.form-range {
  &::-webkit-slider-runnable-track {
    background: main-palette(500);
  }
  &::-webkit-slider-thumb {
    background: accent-palette(500);

    &:hover,
    &:active {
      background: accent-palette(700);
    }
  }
}
