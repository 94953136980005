@import "../variables";

$_types: (
  primary: main-palette(500),
  secondary: accent-palette(500),
  success: $success-color,
  info: $info-color,
  warning: $warning-color,
  danger: $error-color
);

.list-group {
  border-radius: $shape;

  &.stripped {
    .list-group-item:nth-child(odd) {
      background: main-palette(500);
    }
  }
  .list-group-item {
    &:first-child {
      border-top-left-radius: $shape;
      border-top-right-radius: $shape;
    }
    &:last-child {
      border-bottom-left-radius: $shape;
      border-bottom-right-radius: $shape;
    }
    background: transparent;
  }

  @each $_type, $_color in $_types {
    .list-group-item-#{$_type} {
      background-color: $_color;
      color: #fff;
    }
  }
}
