.nav-bar {
  position: fixed;
  top: 0;
  padding: 0;
  margin: 0;
  transition: background 0.2s $animation, transform 0.2s $animation, padding .2s $animation;
  width: 100%;
  z-index: 999;
  @media #{$max991} {
    position: absolute;
  }

  &.side-open {
    transform: translateX($side-bar-width);
  }
  .nav-bar-wrap {
    background: linear-gradient(90deg, main-palette(500) 0, main-palette(300));
    box-shadow: 0 2px 10px 0 rgba(#000, 0.08);
    color: main-contrast(500);
    height: $nav-bar-height;
    position: relative;
    width: 100%;

    .nav-content {
      height: 100%;
      margin: 0 auto;
      padding: 0 $content-h-indents;
      width: 100%;
      @media #{$max1199} {
        padding-left: $content-h-indents / 3 * 2;
        padding-right: $content-h-indents / 3 * 2;
      }

      > .row {
        height: 100%;
      }

      .toggle-button-col {
        align-items: center;
        display: none;
        @media #{$max991} {
          display: flex;
        }

        .toggle-sidebar {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: $module-rem * 2;
          justify-content: space-between;

          .bar {
            background: main-contrast(500);
            min-height: 3px;
            transition: background 0.2s $animation;
            width: $module-rem * 3;
          }
        }
      }
      .title-col {
        align-items: center;
        display: flex;
        position: relative;

        .page-title {
          font-size: 1.5em;
          margin: 0.2rem 0 0;
          transition: opacity 0.2s $animation, color 0.2s $animation;
          @media #{$max543} {
            display: none;
          }

          &.hide {
            opacity: 0;
          }
        }
        .search {
          align-items: center;
          display: flex;
          height: 100%;
          left: 0;
          max-width: 100%;
          padding: 0 15px;
          position: absolute;
          top: 0;
          transform: translateY(-100%);
          width: $module-rem * 40;
          transition: transform .2s ease-in-out;
          z-index: 5;

          @media #{$max767} {
            display: none !important;
          }

          .input-container {
            position: relative;
            width: 100%;

            .search-icon {
              position: absolute;
              left: 2 * $module-rem;
              font-size: 20px;
              opacity: 0.8;
              top: 50%;
              transform: translateY(-50%);
            }
            .search-input {
              background: transparent;
              color: #fff;
              border: 1px solid rgba(#fff, .5);
              height: 40px;
              padding: ($module-rem) ($module-rem) ($module-rem) (5 * $module-rem);
              width: 100%;

              &:active {
                outline: none;
              }
            }
          }
          &.show {
            transform: translateY(0);
          }
        }
      }
      .actions-col {
        position: unset;
        .nav-actions {
          align-items: center;
          display: flex;
          height: 100%;
          margin: 0 (-$module-rem);

          .item {
            margin: 0 $module-rem;
          }
          .toggle-search {
            cursor: pointer;
            @media #{$max767} {
              display: none !important;
            }

            &.active {
              color: accent-palette(500) !important;
            }
          }
          .icon {
            color: inherit;
            &.marker {
              &:before {
                background: accent-palette(500);
                border-radius: 50%;
                content: "";
                display: block;
                height: 8px;
                position: absolute;
                right: 0;
                top: 0;
                width: 8px;
              }
              &.bg-red {
                &:before {
                  background: $error-color;
                }
              }
              &.bg-blue {
                &:before {
                  background: $info-color;
                }
              }
              &.bg-green {
                &:before {
                  background: $success-color;
                }
              }
              &.bg-yellow {
                &:before {
                  background: $warning-color;
                }
              }
            }
          }
        }
      }
    }
  }
  &.boxed {
    .nav-bar-wrap {
      .nav-content {
        max-width: $boxed-width;
      }
    }
  }
  &.light {
    .nav-bar-wrap {
      background: #fff;
      color: #000;

      .nav-content {
        .title-col .search .input-container .search-input {
          color: #000;
          border-color: rgba(#000, .2);
        }
        .toggle-button-col {
          .toggle-sidebar {
            .bar {
              background: main-palette(500);
            }
          }
        }
      }

      .nav-actions .item .nav-link {
        color: #000;

        &:hover {
          color: accent-palette(500);
        }
      }
    }
  }
}

