@import "../variables";

$_bg: (
  default: transparent,
  hover: transparent,
  active: main-palette(500),
  disabled: #ccc,
);
$_border: (
  default: main-palette(500),
  hover: main-palette(500),
  active: main-palette(600),
  disabled: #ccc,
);
$_color: (
  default: main-palette(500),
  hover: main-palette(500),
  active: accent-palette(500),
  disabled: #8e8e8e,
);
$_label-color: (
  default: inherit,
  hover: inherit,
  active: inherit,
  disabled: #ccc,
);

$_border-style: solid;
$_border-width: 2px;
$_fs: 1rem;
$_shape: $shape;
$_animation: $animation;

$_height: $module-rem * 2;
$_width: $module-rem * 2;

.custom-checkbox {
  height: 24px;
  
  .custom-control-label:before {
    align-items: center;
    border-radius: 0;
    box-shadow: none !important;

    display: flex;
    height: 1.25rem;

    justify-content: center;
    left: -1.875rem;
    margin: 0;
    outline: 0 !important;
    padding: 0;
    top: 0;

    transition: background 0.2s ease-in-out, border 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
}

.custom-control {
  padding-left: $_width + $_module-rem;
  transition: color 0.2s $_animation;
}
.form-check-label {
  font-weight: 500;
}
.custom-checkbox {
  .custom-control-label {
    color: map-get($_label-color, default);
    cursor: pointer;
    font-weight: 500;

    &:before,
    &:after {
      align-items: center;
      border-radius: $_shape;
      box-shadow: none !important;
      display: flex;
      height: $_height;
      justify-content: center;
      left: -($_width + $_module-rem);
      margin: 0;
      outline: none !important;
      padding: 0;
      top: 0;
      transition: background 0.2s $_animation, border 0.2s $_animation, transform 0.2s $_animation;
      width: $_width;
    }
    &:before {
      background: map-get($_bg, default);
      border: $_border-width $_border-style map-get($_border, default);
      content: "";
      pointer-events: initial;
    }
    &:after {
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      color: map-get($_color, active);
      content: "\eed6";
      direction: ltr;
      font-family: IcoFont !important;
      font-size: 1.6rem;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      line-height: 1;
      pointer-events: none;
      speak: none;
      text-transform: none;
      transform: scale(0);
      white-space: nowrap;
      word-wrap: normal;
    }
    &:hover {
      color: map-get($_label-color, hover);

      &:before {
        background: map-get($_bg, hover);
        border-color: map-get($_border, hover);
      }
      &:after {
        color: map-get($_color, hover);
        transform: scale(1);
      }
    }
  }
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &:before {
        background: map-get($_bg, active);
        border-color: map-get($_border, active);
      }
      &:after {
        background-image: none !important;
        color: map-get($_color, active);
        transform: scale(1);
      }
    }
    &:disabled ~ .custom-control-label {
      color: map-get($_color, disabled);
      cursor: not-allowed;

      &:before {
        background: map-get($_bg, disabled);
        border-color: map-get($_border, disabled);
      }
      &:after {
        color: inherit;
        transform: scale(0);
      }
    }
    &:disabled:checked ~ .custom-control-label {
      &:before {
        background: map-get($_bg, disabled);
        border-color: map-get($_border, disabled);
      }
      &:after {
        transform: scale(1);
      }
    }
    &:active {
      &:before {
        background: transparent !important;
        border-color: initial !important;
      }
    }
  }
}
